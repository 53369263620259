<template>
  <!-- Contents -->
  <div class="contents" id="contents">
    <div class="inner-lg">
      <!-- Title -->
      <section class="title-section">
        <div class="title-wrap">
          <h2 class="title">News</h2>
          <span class="title-desc">{{$t("content.about.News.txt07")}}</span>
        </div>
      </section>
      <!-- // Title -->

      <!-- HeadLine News List-->
      <section class="headline-section">
        <ul
          v-if="topFixList.length > 0"
          class="headline-list">
          <li
            v-for="(topFix , index) in  topFixList" :key="index"
            :data-aos="index === 0 ? 'fade-right' : 'fade-left'">
            <router-link :to="{ name: 'NewsDetail', params: { newsNo: topFix.news_no, hashTagNo:0 } }"
                         class="headline">
<!--            <a href="#" class="headline">-->
              <div class="image-area">
<!--                <img src="@/assets/images/news/news-bigthum-1.jpg" class="headline-image">-->
                <template v-for="(file,fi_index) in topFix.file_info" >
                  <img
                    v-if="file.code_attach_cata === 'AF105'" :key="fi_index"
                    :src="helperUtils.getFileURL(filePaths.NEWS,file.attach_file_path)"
                    alt="" class="headline-image"  />
                </template>
              </div>
              <div class="text-area" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                <h5>{{topFix.title}}</h5>
                <div class="news-info">
                  <span class="date">
                    {{$moment(topFix.post_date).format('YYYY.MM.DD')}}
                  </span>
                  <ul class="hash-tag-wrap">
                    <li v-for="(hash_tag,ht_index) in topFix.hash_tag_info" :key="ht_index">
                      <span class="hash-tag"># {{hash_tag.hash_tag}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </section>
      <!-- // HeadLine News List-->
    </div>
    <div class="grey-box">
      <div class="inner-lg">
        <!-- Hash Tag Section -->
        <section class="hash-tag-section">
          <ul class="hash-tag-wrap ani-stagger-right">
            <li>
              <a @click="retrieveNewsListByHash(0)" class="hash-tag" :class="{'on': 0 === hasTagIndex }">
                #{{$t("content.common.txt.txt03")}}
              </a>
            </li>
            <li v-for="(hasTag, index) in hasTagList" :key="index">
              <a @click="retrieveNewsListByHash(hasTag.hash_tag_no)" class="hash-tag"
                 :class="{'on': hasTag.hash_tag_no === hasTagIndex }">
                #{{hasTag.hash_tag}}
              </a>
            </li>
          </ul>
        </section>
        <!-- // Hash Tag Section -->

        <!--  News List -->
        <section class="news-list-section">
          <ul
            v-if="newsList.length > 0"
            class="news-list">
            <li
              v-for="(news, index) in newsList" :key="index"
              data-aos="fade-up">
<!--              <a href="#" class="card">-->
              <router-link :to="{ name: 'NewsDetail', params: { newsNo: news.news_no, hashTagNo:hashTagNo } }"
                           class="card">
                <div class="image-area">
                  <template v-for="(file,fi_index) in news.file_info" >
                  <img
                      v-if="file.code_attach_cata === 'AF105'" :key="fi_index"
                      :src="helperUtils.getFileURL(filePaths.NEWS,file.attach_file_path)"
                      alt="" class="card-image"  />
                  </template>
                </div>
                <div class="text-area">
                  <ul class="hash-tag-wrap">
                    <li v-for="(hash_tag,ht_index) in news.hash_tag_info" :key="ht_index">
                      <span class="hash-tag"># {{hash_tag.hash_tag}}</span>
                    </li>
                  </ul>
                  <div class="news-title">{{news.title}}</div>
                  <div class="date">{{$moment(news.post_date).format('YYYY.MM.DD')}}</div>
                </div>
              </router-link>
            </li>

          </ul>
        </section>
        <!-- // News List -->

        <!-- 버튼 -->
<!--        <button @click="retrieveNewsListMore" type="button" :disabled="moreDisabled"-->
<!--                class="btn-more" title="더 보기">-->
<!--          <span v-if="!moreDisabled"><em>더 보기</em></span>-->
<!--        </button>-->
        <MoreButton v-if="moreVisible" @click="retrieveNewsListMore"></MoreButton>
        <!-- // 버튼 -->
      </div>
    </div>
  </div>
  <!-- // CONTENTS -->
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import { APIs, FilePaths } from '@/common/portalApi';
import MoreButton from '@/components/base/MoreButton.vue';

export default {
  name: 'NewsList',
  data() {
    return {
      filePaths: FilePaths,
      helperUtils: mixinHelperUtils.methods,
      topFixList: [],
      newsList: [],
      hasTagList: [],
      hasTagIndex: 0,
      pageNo: 0,
      listCount: 0,
      hashTagNo: 0,
      // moreDisabled: false,
      moreVisible: false,
      totalCnt: 0,
      chkMoreCnt: 0,
    };
  },
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    MoreButton,
  },
  mounted() {
    this.init();
    this.retrieveNewsList();
    this.retrieveHashTagList();
  },
  methods: {
    init() {
      this.pageNo = 0;
      this.listCount = 14;
      this.totalCnt = 0;
      this.chkMoreCnt = 0;
      // this.moreDisabled = false;
      console.log(this.listCount);
      const elVisual = document.getElementsByClassName('visual_intro');
      elVisual[0].className += ' ani_intro';
      this.moreVisible = false;
      if (typeof (this.$route.params.hashTagNo) !== 'undefined') {
        this.hashTagNo = this.$route.params.hashTagNo;
        this.hasTagIndex = this.hashTagNo;
      }
    },
    retrieveNewsList() {
      this.portalApiClient({
        url: APIs.ABOUT_NEWS_LIST_BY_HASHTAG,
        data: {
          page: this.pageNo,
          size: this.listCount,
          contentCategory: null,
        },
      }).then(({ status, data }) => {
        if (data.result) {
          this.chkMoreCnt += data.count;
          this.pageNo += this.listCount;
          this.totalCnt = data.totalCount;
          // 최초 14개 조회
          if (this.listCount > 12) {
            // 2개는 상단고정으로
            if (data.select_cnt > 0) {
              if (data.select_cnt <= 2) {
                this.topFixList = data.list.slice(0, data.select_cnt);
                this.newsList = [];
              } else {
                this.topFixList = data.list.slice(0, 2);
                this.newsList = data.list.slice(2);
              }
            } else {
              this.topFixList = [];
              this.newsList = [];
            }
            // 최초 다음은 12개씩 조회하도록 세팅
            this.listCount = 12;
          } else {
            // 조회된 데이터를 더해준다.
            this.newsList = this.newsList.concat(data.list);
          }
          // more disabled
          if (this.chkMoreCnt >= this.totalCnt) {
            // this.moreDisabled = true;
            this.moreVisible = false;
          } else {
            this.moreVisible = true;
          }
        } else {
          this.showAlert(data.message)
        }
      });
    },
    // 헤시테그 리스트 조회
    retrieveHashTagList() {
      this.portalApiClient({
        url: APIs.HASHTAG_LIST,
        data: {
        },
      }).then(({ status, data }) => {
        if (status === 200) {
          this.hasTagList = data;
        } else {
          console.log('Fail');
        }
      });
    },
    // 헤시테그 클릭시
    retrieveNewsListByHash(hashTagNo) {
      this.init();
      this.hasTagIndex = hashTagNo;
      this.hashTagNo = hashTagNo;
      this.retrieveNewsList();
    },
    // 더보기 버튼 클릭시
    retrieveNewsListMore() {
      this.retrieveNewsList();
    },
  },
};
</script>

<style scoped>

</style>
